
<template>
<div  >

  <v-textarea

      dense
      :value="value"
      readonly
      hide-details
      outlined
      :label="label"
      :no-resize="noresize"
      :rows="rows"
      @click="visibility=false; val2=value;$emit('update:visibility', visibility);;"
      @focusin="visibility=false;$emit('update:visibility', visibility);val2=value;"
      v-if="visibility"
      :style="'background:'+ color+'; ;width:'+width+'px; '"


  ></v-textarea><v-textarea
    v-model="val2"
    dense
    hide-details
    :rows="rows"
    :no-resize="noresize"
    outlined
    :label="label"
    v-if="!visibility"
    @keydown.enter="()=>{if (!allowenter) {value=val2;visibility=true;$emit('update:visibility', visibility);$emit('update:value', value);$emit('change')}}"
    @change="value=val2;visibility=true;$emit('update:visibility', visibility);$emit('update:value', value);$emit('change')"
    @focusout="value=val2;visibility=true;$emit('update:visibility', visibility);$emit('update:value', value);$emit('change')"
    @mousemove="$emit('update:value', val2);$emit('change')"

    :style="'background:'+ color+'; width:'+width+'px;'"


></v-textarea>

  <!--      @input="$emit('update:visibility', visibility); nocalc? $emit('update:value', rez):$emit('update:value', value) ;$emit('update:formula', formula);;"-->



</div>
</template>


<script>


import Vue from 'vue';
export default {

  props: {
    value: {
      type:  String,
      default:  '0',
    }  ,
  rows: {
    type:  Number,
    default:  1,
  },
  noresize: {
    type:  Boolean,
    default:  false,
  },
    allowenter: {
      type:  Boolean,
      default:  false,
    },
    label:{
      type: String,
      default: ""
    },
    val2: {
      type: String,
      default: "0"
    },
    visibility: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '100%'
    },
    color: {
      type: String,
      default:"#FFFFFF"
    },



  },
  data: () => ({
    visible:true,
    vl:0,
    form:'0',






  }),
  methods :{


    setval(val)
    {
      this.value=val
    },


  },

  mounted() {
    this.visible=this.visibility
    this.val2=this.value




  },

  computed: {



    rez2()
    {

        Vue.set(this,'value',this.val2);
        return String(this.val2)

    },

  }



}









</script>
<style>



</style>
