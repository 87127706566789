
<template>
<div :name="rez2" :style="' height: 100%;  position: relative; margin:0px; background-color:'+ color+';' " >

  <v-text-field

      dense
      :value="rez3"
      readonly
      hide-details
      @click="visibility=false;$emit('update:visibility', visibility);"
      @focusin="visibility=false;$emit('update:visibility', visibility);"
      v-if="visibility"
      :style="'background:'+ color+';height:100%;margin:0px; ;width:'+width+'px;background-image: '+(mark?'linear-gradient(-270deg, rgb(32, 138, 0), rgb(32, 138, 0) 4px, transparent 4px, transparent); ':';')"


  ></v-text-field><v-text-field
    v-model="formula"
    dense
    hide-details
    :readonly="readonly"

    v-if="!visibility"
    @change="visibility=true;$emit('update:visibility', visibility);$emit('update:value', rez);$emit('update:formula', formula);$emit('change')"
    @focusout="visibility=true;$emit('update:visibility', visibility);$emit('update:value', rez);$emit('update:formula', formula);$emit('change')"

    :style="'background:'+ color+';height:100%;margin:0px; width:'+width+'px;'"


></v-text-field>

  <!--      @input="$emit('update:visibility', visibility); nocalc? $emit('update:value', rez):$emit('update:value', value) ;$emit('update:formula', formula);;"-->



</div>
</template>


<script>


import Vue from 'vue';
export default {

  props: {
    mark: {
      type:  Boolean,
      default:  false,
    },
    value: {
      type:  String,
      default:  '0',
    },
    readonly: {
      type:  Boolean,
      default:  false,
    },
    formula: {
      type: String,
      default: "0"
    },
    visibility: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '100%'
    },
    color: {
      type: String,
      default:"#FFFFFF"
    },
    decimal: {
      type: String,
      default:"2"
    },
    nocalc: {
      type: Boolean,
      default: false
    },
    formulavalue: {
      type: Boolean,
      default: false
    }

  },
  data: () => ({
    visible:true,
    vl:0,
    form:'0',






  }),
  methods :{

    removeleadingzero(expression)

    {
      let rez=''
      let i=0
      let buffer=''

      while (i<expression.length)
      {


        if (i>expression.length-2)
        {

          rez=rez+(buffer+expression.substr(i,1)).replace(/^0+(?!$)/,'')

          buffer=''

        }
        else
        {

          if (['+','-','*','/','(',')','^'].indexOf(expression.substr(i,1))<0)
          {

            buffer=buffer+expression.substr(i,1)

          }
          else
          {
            rez=rez+buffer.replace(/^0+(?!$)/,'')+expression.substr(i,1)

            buffer=''
          }


        }



        i=i+1
      }

      return rez
    },

    setval(val)
    {
      this.value=val
    },


  },

  mounted() {
    this.visible=this.visibility
    if (this.formulavalue)
    {
      this.formula=String(this.value);

    }
  },

  computed: {
    rez3()
    {

      let rezult=0

      if (this.nocalc)
      {
        try {
          rezult=(isNaN(Number(this.value)) || !isFinite(Number(this.value)))?'--':Number(this.value).toLocaleString('ru-ru',{ minimumFractionDigits: this.decimal,maximumFractionDigits: this.decimal })

        }
        catch {
          rezult='--'
        }
      }
      else
      {
        try {
          rezult=(isNaN(Number(this.rez)) || !isFinite(Number(this.rez)))?'--':Number(this.rez).toLocaleString('ru-ru',{ minimumFractionDigits: this.decimal,maximumFractionDigits: this.decimal })

        }
        catch {
          rezult='--'
        }
      }

      return rezult
    },
    rez2()
    {
      if (this.formulavalue)
      {
        Vue.set(this,'formula',String(this.value).replace('.',','));

      }
      return String(this.value)

    },
    rez()
    {
      try {




        let formula = this.formula;
        window.console.log(formula+'--1')


        //заменяем знак запятой
        formula=formula.replace(/,/g,".");
        window.console.log(formula+'--2')
        //заменяем знак равенства
        formula=formula.replace(/=/g,"");

        //заменяем знак $
        formula=formula.replace(/\$/g,"");
        //заменяем %
        formula=formula.replace(/%/g,"*0.01");
        //заменяем пробелы
        formula=formula.replace(/\s/g,"");
        //заменяем ^
        formula=formula.replace(/\^/g,"**");

        //убираем лидирующие нули
        formula=this.removeleadingzero(formula);



        let rez=''
        rez=eval(formula)
        if (!rez)
        {
          rez=''
        }
        this.setval(rez);
        return rez

      }
      catch
      {return "Ошибка!"}




    }
  }



}









</script>
<style>



</style>
