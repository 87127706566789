
<template>
<div  >

  <div v-html="value"
       contentEditable="true"

       @mousemove="visibility=false; val2=value;"

       v-if="visibility"
       style="width:170mm; max-width:170mm; color:#000000; font-size: 14pt; text-indent: 15mm;
       font-family: 'Times New Roman';        padding:5px; text-align:justify; line-height:1.5rem;
       border:2px solid #D7D6CF; min-height:30px ;  border-radius:10px;margin:15px; padding:10px ">

  </div>
  <div v-html="val2"
       id="thisdiv"
       contentEditable="true"
       style="width:170mm; max-width:170mm; color:#000000; font-size: 14pt; text-indent: 15mm;
       font-family: 'Times New Roman';        padding:5px; text-align:justify; line-height:1.5rem;
       border:2px solid #D7D6CF; min-height:30px ;  border-radius:10px;margin:15px; padding:10px "
       v-if="!visibility"

       @focusout="value=$event.target.innerHTML; $emit('changer',$event.target.innerHTML);visibility=true;"
       @mouseout="gethtml($event.target)"



  >

  </div>














  <!--      @input="$emit('update:visibility', visibility); nocalc? $emit('update:value', rez):$emit('update:value', value) ;$emit('update:formula', formula);;"-->



</div>
</template>


<script>


import Vue from 'vue';
export default {

  props: {
    value: {
      type:  String,
      default:  '',
    }  ,

    val2: {
      type: String,
      default: ""
    },
    visibility: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '100%'
    },
    color: {
      type: String,
      default:"#FFFFFF"
    },



  },
  data: () => ({
    visible:true,
    vl:0,
    form:'0',






  }),
  methods :{
    gethtml(ss)

    {
      if (ss.id=='thisdiv')
      {
        this.value=ss.innerHTML;
        this.$emit('changer',ss.innerHTML);
        this.visibility=true;
        window.console.log(this.value)

       }

    },


    setval(val)
    {
      this.value=val
    },


  },

  mounted() {
    this.visible=this.visibility
    this.val2=this.value




  },

  computed: {



    rez2()
    {

        Vue.set(this,'value',this.val2);
        return String(this.val2)

    },

  }



}









</script>
<style>



</style>
