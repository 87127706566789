<style>
@font-face {
    font-family: 'Helvetica';
    src: local('Helvetica Regular'), local('Helvetica-Regular'),
        url('Helvetica.woff2') format('woff2'),
        url('Helvetica.woff') format('woff'),
        url('Helvetica.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue Heavy'), local('Helvetica-Neue-Heavy'),
        url('HelveticaNeueCyr-Heavy.woff2') format('woff2'),
        url('HelveticaNeueCyr-Heavy.woff') format('woff'),
        url('HelveticaNeueCyr-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @page {
      size:A4 portrait;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin: 0;
      -webkit-print-color-adjust: exact;
      background: rgb(255, 255, 255);
  }

  body
    {
      background-image: url("/fon.png") ;
      background-repeat: repeat;;

    }
  </body>
  .sortable-chosen {
    opacity: 0.7;
    background-color: #dcdcdc;
  }

  .sortable-ghost {
    background-color: #dcdcdc;
  }

  .flip-list-move {
    transition: transform 0.1s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.85;
    background: #64fa6a;
  }

  .list-group {
    min-height: 20px;
  }

  <style>.image {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border: 10px solid #ebebeb;

    margin: 0 auto;


  }

  .link {
    border-top: 35px solid transparent;
    padding: 5px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    display: inline-block;
    padding-top: 78px;
    margin-top: -78px;
  }

  .head_podacha {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 10px;
    background: #f0f0f0;
    color: #717171;
    word-wrap: break-word;
    font-size: 1.2rem;
    max-width: 95%;
    min-width: 400px;
    line-height: 2.1rem;
    text-align: justify;
    border: 1px solid rgb(219, 218, 218);
    box-shadow: 2px 2px 1px rgba(142, 139, 139, 0.15);
    z-index: 35;



  }

  .head_podacha_green {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 10px;
    background: #dfffd0;
    color: #3ab800;
    font-size: 1.2rem;
    width: 95%;
    line-height: 2.1rem;
    text-align: left;
    border: 1px solid rgb(219, 218, 218);
    box-shadow: 2px 2px 1px rgba(142, 139, 139, 0.15);
    z-index: 35;



  }

  .selected {
    background-color: #f9c7c8;
    border: solid red 1px;
  }

  .flip-list-move {
    transition: transform 0.1s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.85;
    background: #64fa6a;
  }

  .list-group {
    min-height: 20px;

  }
</style>


<template>

  <v-app id="app" style="max-width:800px;margin:0px auto;  ">




    <v-app-bar app color="#009d10" dark  style="max-width:800px; height:80px; margin:0px auto;" >



      <!-- <v-divider class="mx-4" inset vertical></v-divider>
    <div class="white--text text-center shadow" style="line-height:1.15rem;font-size:1.25rem; margin-top:5px"> Документ - онлайн <br /><span style="font-size: 0.69rem;  opacity:0.9; color: #ffffff">ООО «Бюро тарифных расчетов»</span></div>
    <v-divider class="mx-4" inset vertical></v-divider>
    -->


      <v-toolbar-title style="width:100%" >
        <div style="display: block;  border-bottom:20px solid rgba(0, 119, 18, 0.65); margin-left:-20px;margin-top:10px"></div>





          <table class="overline" cellspacing="0" cellpadding="0" style=" display: block;text-align:left;   ">
    <tr>
      <td style="font-size:clamp(11pt, 4.8vw,12pt); padding-top: 5px; ">

        <div style="display:inline-block; margin-right:5px; ">

          <span style=" text-shadow: 1px 1px 2px rgba(97, 7, 145, 0.69);">
            <a style="text-decoration:none; color:white;" href="/">
            <v-icon x-large class="ml-0 " style="color:rgb(255, 255, 255); text-shadow: 1px 1px 7px rgb(21, 56, 9);margin-top:-8pt; font-weight:50;">mdi-numeric-2-box</v-icon>
            <v-icon x-large class="ml-0 " style="color:rgb(255, 255, 255); text-shadow: 1px 1px 7px rgb(21, 56, 9);margin-top:-8pt; font-weight:50;">mdi-numeric-6-box</v-icon>
            <v-icon x-large class="ml-0 " style="color:rgb(255, 255, 255); text-shadow: 1px 1px 7px rgb(21, 56, 9);margin-top:-8pt; font-weight:50;">mdi-numeric-1-box</v-icon>

            .РФ
            </a>
          </span>





        </div>


        </td>
        <td>
            <a style="text-decoration:none; color:white;" href="/">
          <v-icon   style="color:rgb(255, 255, 255);margin-top:-20pt; margin-left:-45px; font-weight:50; opacity:50%;">mdi-leaf</v-icon>
            </a>

        </td>
        <td  style="font-size:clamp(9pt, 1.0vw,12pt); padding-top: 15px; letter-spacing:clamp(0.05rem, 1.0vw,0.12rem);; padding-left:10px ">
        <div style="display:inline-block;line-height:clamp(1rem, 1.0vw,1.2rem); ">

        <a style="text-decoration:none; color:white;" href="/">  <p style=" text-shadow: 1px 1px 2px rgba(97, 7, 145, 0.69); ">ПРОГРАММЫ<br/>ЭНЕРГОСБЕРЕЖЕНИЯ</p>
          </a>
        </div>
      </td>

    </tr>

  </table>


      </v-toolbar-title>

    </v-app-bar>






    <v-main style="border:3px solid rgb(213, 213, 213); background:url('/fon-7.png'); ">


      <v-divider class=" mt-0" />

<router-view />

<p class="body-1" align="center" style="color:black; page-break-inside:avoid;  width:100%; margin:0px auto; padding:10px;padding-top:15px; line-height:1.2rem ">
  ООО «Бюро тарифных расчетов»
<br>


e-mail: <a style="color:black;" href="mailto:info@btr-online.ru">info@btr-online.ru</a>
<br>
<a style="color:black;" href="tel:+78462216058">8 (846) 221-60-58</a>
<br>
   <a style="color:black;" href="http://btr-online.ru">btr-online.ru</a>
</p>

    </v-main>




  </v-app>


</template>



<script>
  // Enable pusher logging - don't include this in production
  //Pusher.logToConsole = true;



  const axios = require('axios').default


  //import draggable from "vuedraggable"

  import Vue from 'vue';
  import VueCookies from 'vue-cookies';
  import VueExcelEditor from 'vue-excel-editor';




  Vue.use(VueCookies);
  Vue.use(VueExcelEditor);










  //import html2canvas from 'html2canvas';

  Vue.filter('kb', val => {
    return Math.floor(val / 1024);
  });

  export default {
    name: 'App',

    props: {
      source: String,
    },
    data: () => ({

      snackbarOK: false,
      dialogok:false,
      oktext:'',
      errtext:'',
      snackbarErr: false,





      formnewrogHasErrors: false,


      titlerab:'Программа на ... годы по участку ... теплоснабжения',
      emailrab:'',
      emailrab2:'',

      rules: {
         required: value => !!value || 'Не заполнено',
         counter: value => value.length <= 20 || 'Max 20 characters',
         email: value => {
           const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
           return pattern.test(value) || 'Проверьте e-mail.'
         },


          },


      RI:[],
      rizero:false,
      ri_caption:{'TS': 'Теплоснабжение', 'HVS':'Водоснабжение','VO':'Водоотведение','GVS':'ГВС'},
      RIprogress:false,
      firms:[],
      prikazieedata:[],
      full:'',
      short:'',
      mo:'',
      prikaziprogress:false,
      printvisible:false,
      girbozero:false,
      ermspprogress:false,
      egrulprogress:false,
      rosstatprogress:false,
      fnsprogress:false,
      err:{message:''},
      egruldata:[],
      mspdata:[],
      fnsdata:[],
      rosstatdata:[],
      prikazidata:[],
      inn:"",
      tab1:null,
      te:true,
      trte:false,
      tn:false,
      gvs:false,
      vs:true,
      vo:true,
      trvs:false,
      trvo:false,
      ee:true,
      osno:true,
      usno:false,
      nebudg:true,
      budg:false,
      nemp:true,
      mp:false,
      nep4:false,
      p4:true,
      do15:false,
      ot15:true,
      tereg:true,
      tenereg:false,
      helpdialog:false,
      vd:['te',	'tn',	'gvs',	'vs',	'vo',	'trte',	'trvs',	'trvo',	'ee',	'osno',	'usno',	'nebudg',	'budg',	'nemp',	'mp',	'nep4',	'p4',	'do15',	'ot15',	'tereg',	'tenereg'],
      vdtext:['Теплоснабжение',	'Теплоноситель',	'ГВС',	'Водоснабжение',	'Водоотведение',	'Транспортировка тепловой энергии',	'Транспортировка воды',	'Транспортировка стоков',	'Передача электроэнергии',	'osno',	'usno',	'nebudg',	'budg',	'nemp',	'mp',	'nep4',	'p4',	'do15',	'ot15',	'tereg',	'tenereg'],
      nalog:[],

      months:['Отчёты и документы с изменяющимися датами сдачи','Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      monthshort:['','янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
      calendar:[],
      sendingprocessdown:false,
      download:false,
      flagnow:false,
      recordobj:'',
      overlayer:false,

      flag_stop_redact:0,

                 ch1:[{message:''}],



      emailadd: false,
      sending: false,
      word: '',
      about: false,
      balance_upload: 0,
      commentaddindex: '',
      commentaddmsg: '',
      commentadd: false,

      showallperechen: true,
      tomsize_font: '16',
      expanded: [],

      tab: null,
      sel_reestr: "",
      per_direction: 2,
      centerlabel: true,
      sel_delo: {},
      sel_podacha: {},
      kind_titul: 'T',
      scale: 5,
      search: "",
      search1: "",
      move_doc: false,
      move_flag: false,
      dialog_edit_text: "",
      doc: [],
      list_dela: [],
      podacha1: {},
      files: [],
      output: null,
      org_height: 120,
      newprog: false,
      dialog_titul: false,

      dialog_perechen: false,
      notice_print: false,
      label_print: false,
      label_count: 2,
      label_fontsize: 16,
      label_width: 200,
      label_height: 55,
      label_tom: " ",
      settings_pod: false,

      titul_text: "",
      pd: {},

      id: '0',
      show_ov_1: false,
      docs_over: [],
      show_ov_2: false,
      show_ov_3: false,
      eias_over: [],
      files_over: [],
      reestr_over: [],
      kods: [],
      file_links: [],
      index: null,
      drawer: null,
      dis: false,
      show: false,
      stroka_vivod: "",
      orgs: false,
      notes: false,
      jobs: false,
      navigation: {
        shown: true,
        width: 500,
        borderSize: 3
      },




    }),
    watch: {


    },


    computed: {


      formnewprog () {
        return {
          titlerab: this.titlerab,
          emailrab: this.emailrab,
          emailrab2: this.emailrab2,

        }
     },



      direction() {
        return this.navigation.shown === false ? "Open" : "Closed";
      },







    },

    methods: {

      getData() {
          window.console.log(this.$refs.spreadsheet.current[0].getData());
       },



      submitnewprog () {
        this.formnewrogHasErrors = false
        let self = this;

        Object.keys(this.formnewprog).forEach(f => {
          if (!this.formnewprog[f]) this.formnewrogHasErrors = true

          this.$refs[f].validate(true);


        })

        if (!this.formnewrogHasErrors)
        {
          self.overlayer=true;

          axios.post(
              'https://261.рф/NewPE',
              JSON.stringify({
                  enter: "123",
                  titlerab:self.titlerab,
                  emailrab:self.emailrab
              })
            )
            .then(function(response) {

              if (
                self.Isnoproblem(response.data))
              {
                self.overlayer=false;

                window.console.log(response.data[0]);
                self.oktext="Программа создана! Ссылка на Вашу программу находится в письме, направленном Вам по электронной почте - " + response.data[0]['email_rab'] + "." ;
                self.emailrab="";
                self.titlerab='Программа на ... годы по участку ... теплоснабжения';
                self.emailrab2="";
                self.newprog=false;
                self.dialogok=true;
              }
              else {
                self.overlayer=false;
                self.errtext="Ошибка! Попробуйте повторить позднее.";
                self.snackbarErr=true;
              }

            })

            .catch(function(error) {
              self.overlayer=false;
              window.console.log(error);
            })

        }
      },




      exportAsExcel () {
       const format = 'xlsx'
       const exportSelectedOnly = false
       const filename = 'test'
       this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
   },
   exportAsCsv () {
       const format = 'csv'
       const exportSelectedOnly = false
       const filename = 'test'
       this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
   },



      printoutcalendar()
      {
                //
                // let divToPrint = window.document.getElementById("calendarprint");
                // let newWin = window.open('');
                // newWin.document.write(divToPrint.outerHTML);
                // newWin.print();



                window.setTimeout(() => {
                const prtHtml = document.getElementById('calendarprint').innerHTML;

                // Get all stylesheets HTML
                let stylesHtml = '';

                for (const node of [...document.querySelectorAll('link[rel="stylesheet"]')]) {
                  stylesHtml += node.outerHTML;
                }


                // Open the print window

                const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                    ${stylesHtml}
                  </head>
                  <body style="background: rgb(255, 255, 255);">
                    ${prtHtml}
                  </body>
                </html>`);



                  WinPrint.document.close();
                  WinPrint.focus();

                  window.setTimeout(() => {WinPrint.focus(); WinPrint.print()}, 2000)
                  this.printvisible=false;
                }, 1000)
                  //WinPrint.print();
                  //WinPrint.close();



            },

      validateInn(inn, error) {
 var result = false;
 if (typeof inn === 'number') {
   inn = inn.toString();
 } else if (typeof inn !== 'string') {
   inn = '';
 }
 if (!inn.length) {

   error.message = 'ИНН пуст';
 } else if (/[^0-9]/.test(inn)) {

   error.message = 'ИНН может состоять только из цифр';
 } else if ([10, 12].indexOf(inn.length) === -1) {

   error.message = 'ИНН может состоять только из 10 или 12 цифр';
 } else {
   var checkDigit = function (inn, coefficients) {
     var n = 0;
     for (var i in coefficients) {
       n += coefficients[i] * inn[i];
     }
     return parseInt(n % 11 % 10);
   };
   switch (inn.length) {
     case 10:
       var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
       if (n10 === parseInt(inn[9])) {
         result = true;
       }
       break;
     case 12:
       var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
       var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
       if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
         result = true;
       }
       break;
   }
   if (!result) {

     error.message = 'Неправильное контрольное число';
   }
 }
 if (result)
 {
   this.girbozero=false;this.rizero=false;this.egruldata=[];this.mspdata={'data':[]};this.fnsdata=[];this.rosstatdata=[]; this.prikazieedata=[]; this.full=''; this.short='';this.mo=''
   this.prikazidata=[]; this.RI=[]; this.getRI();this.rosstat();this.girbo();this.egrul();this.msp(); this.prikaziee();
   this.err.message=""
 }
 else {
   this.err.message=error.message
 }

 return result;
},

      rosstat()
      {
        var self = this;
        self.rosstatprogress=true;
        axios.post(
            'http://calendar.btr-online.ru/Getrosstat',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {
            if (
              response.data != "ERROR") {
                self.rosstatdata=response.data;
                self.rosstatprogress=false;

            } else {
              self.rosstatprogress=false;
            }
          })
          .catch(function(error) {
            window.console.log(error);
            self.rosstatprogress=false;
          });
      },


            prikazi()
            {



              var self = this;
              var short='--';
              var full='--';
              var mo="--";
              self.prikaziprogress=true;

              if (self.short!='')
              {
                if (self.short.indexOf('"')>-1)
                {
                  short='«'+self.short.match(/"(.+)"/)[1]+'»'
                }
                else {
                  short='«'+self.short+'»'
                }

              }

              if (self.full!='')
              {
                if (self.full.indexOf('"')>-1)
                {
                  full='«'+self.full.match(/"(.+)"/)[1]+'»'
                }
                else {
                  full='«'+self.full+'»'
                }

              }

              if(self.fnsdata.okopf.name.indexOf('Муниц')>-1)
              {

                if (self.fnsdata.city)
                {
                  mo=self.fnsdata.city.slice(0,-3)
                }
                else {
                  mo=self.fnsdata.district.slice(0,-3)
                }



              }

              axios.post(
                  'http://calendar.btr-online.ru/prikazi',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,
                      short: short,
                      mo:mo,
                      full:  full,
                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.prikazidata=response.data;
                      self.prikaziprogress=false;

                  } else {
                    self.prikaziprogress=false;
                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.prikaziprogress=false;
                });
            },
            prikazi2()
            {



              window.setTimeout(() => {



                var self = this;
                var short='--';
                var full='--';
                var mo='--';

                this.log('prikazi2');
                self.prikaziprogress=true;
                if (self.egruldata)
                {

                  if ((self.egruldata['rows'][0].k=='fl'))

                  {


                    self.short=self.egruldata['rows'][0].n.split(" ")[0].slice(0,-2);
                    self.full=self.egruldata['rows'][0].n.split(" ")[1].slice(0,1)+'.'+self.egruldata['rows'][0].n.split(" ")[2].slice(0,1)+'.';
                    self.prikazi3();
                    return;


                  }

                 else {



                   if (self.egruldata['rows'][0].c!='')
                   {
                     if (self.egruldata['rows'][0].c.indexOf('"')>-1)
                     {
                       short='«'+self.egruldata['rows'][0].c.match(/"(.+)"/)[1]+'»'
                     }
                     else {
                       short='«'+self.egruldata['rows'][0].c+'»'
                     }

                   }

                   if (self.egruldata['rows'][0].n!='')
                   {
                     if (self.egruldata['rows'][0].n.indexOf('"')>-1)
                     {
                       full='«'+self.egruldata['rows'][0].n.match(/"(.+)"/)[1]+'»'
                     }
                     else {
                       full='«'+self.egruldata['rows'][0].n+'»'
                     }

                   }

                 }
               }






                axios.post(
                    'http://calendar.btr-online.ru/prikazi',
                    JSON.stringify({
                        enter: "123",
                        inn:self.inn,
                        short:short,
                        mo:mo,
                        full: full,

                    })
                  )
                  .then(function(response) {
                    if (
                      response.data != "ERROR") {
                        self.prikazidata=response.data;
                        self.prikaziprogress=false;

                    } else {
                      self.prikaziprogress=false;
                    }
                  })
                  .catch(function(error) {
                    window.console.log(error);
                    self.prikaziprogress=false;
                  });


              }, 3000)


            },

            prikazi3()
            {
              var self = this;
              self.prikaziprogress=true;

              axios.post(
                  'http://calendar.btr-online.ru/prikaziip',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,
                      short: self.short,
                      full:  self.full
                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.prikazidata=response.data;
                      self.prikaziprogress=false;

                  } else {
                    self.prikaziprogress=false;
                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.prikaziprogress=false;
                });
            },
            prikaziee()
            {
              var self = this;
              self.prikaziprogress=true;

              axios.post(
                  'http://calendar.btr-online.ru/prikaziee',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,

                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.prikazieedata=response.data;
                      self.prikaziprogress=false;

                  } else {
                    self.prikaziprogress=false;
                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.prikaziprogress=false;
                });
            },
            getRI()
            {
              var self = this;
              self.prikaziprogress=true;

              axios.post(
                  'http://calendar.btr-online.ru/GetRINFO',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,

                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.RI=response.data;
                      self.RIprogress=false;
                      if (self.RI.length===0)
                      {
                          self.rizero=true;

                      }

                  } else {
                    self.RIprogress=false;

                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.RIprogress=false;
                });
            },

      girbo()
      {
        var self = this;
        self.fnsprogress=true;
        axios.post(
            'http://calendar.btr-online.ru/girbo',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {
            if (response.data != "ERROR") {
                self.fnsdata=response.data;
                self.fnsprogress=false;
                self.full=self.fnsdata['fullName'];
                self.short=self.fnsdata['shortName'];
                self.prikazi()

            } else {
            self.fnsprogress=false;
            self.girbozero=true;
            self.prikazi2();



            }
          })
          .catch(function(error) {
            window.console.log(error);
            self.fnsprogress=false;
            self.prikazi2();

          });
      },

      egrul()
      {
        var self = this;
        self.egrulprogress=true;
        axios.post(
            'http://calendar.btr-online.ru/egrul',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {
            if (
              response.data != "ERROR") {
                self.egruldata=response.data;
                self.egrulprogress=false;

            } else {

              self.egrulprogress=false;
            }
          })
          .catch(function(error) {
            window.console.log(error);
            self.egrulprogress=false;
          });
      },
      msp()
      {
        var self = this;
        self.ermspprogress=true;
        axios.post(
            'http://calendar.btr-online.ru/Getmsp',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {


            try {


            if ( (  response.data !== "ERROR") && (('ERROR' in response.data)==false)  ) {
                self.mspdata=response.data;
                self.ermspprogress=false;

            } else
            {
              self.ermspprogress=false;
            }
          }
            catch(error) {
              window.console.log(error);
              self.ermspprogress=false;
            }
          })

      },

      refresh() {
    window.location.reload();
},
       convertToBoolean(sss) {
          try {
              return JSON.parse(sss.toLowerCase());
          }
          catch (e) {
              return undefined;
          }
      },

      savecookie()
      {

          this.vd.forEach((item) => {
              this.$cookies.set(item, this[item],31556926);
          });

      },


showwidth()
{
  return window.document.documentElement.clientWidth
},








      Isnoproblem(str) {
        try {
          str.trim();
        } catch (e) {
          return true;
        }
        return false;
      },

      log(dd) {
        window.console.log(dd)
      },














    },

    mounted() {



      if (!this.$cookies.isKey('te'))
      {



                    this.vd.forEach((item) => {
                        this.$cookies.set(item, this[item],31556926);
                    });

      }
      else {

        this.vd.forEach((item) => {

            this[item]=this.convertToBoolean(this.$cookies.get(item));
        });

      }










    },

  };
</script>
