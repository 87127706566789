
<template>
<div  >

  <div v-html="value"
       contentEditable="true"

       @mousemove="visibility=false; val2=value;"

       v-if="visibility"
       style=" color:#000000; font-size: 10pt; text-indent: 0mm;
       font-family: 'Times New Roman';         text-align:left; line-height:0.95rem;
       border:2px solid #D7D6CF; min-height:100px ; max-height: 100px; overflow-y: scroll;  border-radius:10px; padding:10px ">

  </div>
  <div v-html="val2"
       contentEditable="true"
       style=" color:#000000; font-size: 10pt; text-indent: 0mm;
       font-family: 'Times New Roman';         text-align:left; line-height:0.95rem;
       border:2px solid #D7D6CF; min-height:100px ;max-height: 100px;overflow-y: scroll;  border-radius:10px; padding:10px "
       v-if="!visibility"
       @keypress.enter="$emit('changer',$event.target.innerHTML);"
       @focusout="value=$event.target.innerHTML; $emit('changer',$event.target.innerHTML);visibility=true;"
  >

  </div>














  <!--      @input="$emit('update:visibility', visibility); nocalc? $emit('update:value', rez):$emit('update:value', value) ;$emit('update:formula', formula);;"-->



</div>
</template>


<script>


import Vue from 'vue';
export default {

  props: {
    value: {
      type:  String,
      default:  '',
    }  ,

    val2: {
      type: String,
      default: ""
    },
    visibility: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '100%'
    },
    color: {
      type: String,
      default:"#FFFFFF"
    },



  },
  data: () => ({
    visible:true,
    vl:0,
    form:'0',






  }),
  methods :{


    setval(val)
    {
      this.value=val
    },


  },

  mounted() {
    this.visible=this.visibility
    this.val2=this.value




  },

  computed: {



    rez2()
    {

        Vue.set(this,'value',this.val2);
        return String(this.val2)

    },

  }



}









</script>
<style>



</style>
