import '@babel/polyfill'
import Vue from 'vue'

import App from './App.vue'
import VueRouter from "vue-router"
import Home from "@/components/Home";
import AuthComponent from "@/components/AuthComponent";
import vuetify from './plugins/vuetify';

Vue.use(VueRouter);
Vue.config.productionTip = false






const routes = [
  {
    path: "",
    component: Home,
  },

{
  path :'/getprogram',
  component:AuthComponent,
  children: [
      {
        path: '/getprogram/:kod',

      }
    ]
},

{
  path :'*',
  component:Home
},
];

const router = new VueRouter({
  routes,
  mode: "history",
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
