<style>


  @page {
      size:A4 portrait;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin: 0;
      -webkit-print-color-adjust: exact;
      background: rgb(255, 255, 255);
  }

  body
    {
      background-image: url("/fon.png") ;
      background-repeat: repeat;;
    }
  </body>
  .sortable-chosen {
    opacity: 0.7;
    background-color: #dcdcdc;
  }

  .sortable-ghost {
    background-color: #dcdcdc;
  }

  .flip-list-move {
    transition: transform 0.1s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.85;
    background: #64fa6a;
  }

  .list-group {
    min-height: 20px;
  }

  <style>.image {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border: 10px solid #ebebeb;

    margin: 0 auto;


  }

  .link {
    border-top: 35px solid transparent;
    padding: 5px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    display: inline-block;
    padding-top: 78px;
    margin-top: -78px;
  }

  .head_podacha {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 10px;
    background: #f0f0f0;
    color: #717171;
    word-wrap: break-word;
    font-size: 1.2rem;
    max-width: 95%;
    min-width: 400px;
    line-height: 2.1rem;
    text-align: justify;
    border: 1px solid rgb(219, 218, 218);
    box-shadow: 2px 2px 1px rgba(142, 139, 139, 0.15);
    z-index: 35;



  }

  .head_podacha_green {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 10px;
    background: #dfffd0;
    color: #3ab800;
    font-size: 1.2rem;
    width: 95%;
    line-height: 2.1rem;
    text-align: left;
    border: 1px solid rgb(219, 218, 218);
    box-shadow: 2px 2px 1px rgba(142, 139, 139, 0.15);
    z-index: 35;



  }

  .selected {
    background-color: #f9c7c8;
    border: solid red 1px;
  }

  .flip-list-move {
    transition: transform 0.1s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.85;
    background: #64fa6a;
  }

  .list-group {
    min-height: 20px;

  }
</style>


<template>




<div >

      <router-view />

      <p class="body-0 pr-4 pl-4 mb-4  text-justify" style="background:rgba(224, 255, 200, 0.44); line-height:1.2rem; color:black; border:1px solid rgb(236, 234, 234); width:95%; margin:0px auto; padding:15px; border-radius:15px; margin-top:45px; ">

  <v-icon   class="mr-2" color="grey darken-3" style="text-shadow:0px 0px 1px rgb(255,255,255)" >mdi-web</v-icon>Онлайн-платформа по разработке программ энергосбережения и повышения энергоэффективности для регулируемых организаций.
    </p>
  <p class="body-0 pr-4 pl-4 mb-4 mt-4 text-justify" style="background:rgba(224, 255, 200, 0.44); line-height:1.2rem; color:black;  border:1px solid rgb(236, 234, 234); width:95%; margin:0px auto; padding:15px; border-radius:15px; margin-top:45px; ">
    <span color="grey darken-3" ><v-icon color="grey darken-3"   style="text-shadow:0px 0px 1px rgb(255, 255, 255);transform:rotate(15deg); "   class="mr-2" >mdi-lightning-bolt</v-icon>Создайте свою программу энергосбережения и повышения энергоэффективности, а также отчёт к ней онлайн и бесплатно.</span></p>








<div class="body-0  mb-4 mt-4 text-justify" style="  color:#333; width:95%; margin:0px auto;  border-radius:15px;padding-bottom:10px ">



<div class="text-center ;" style=" margin-left:-20px; margin-right:-20px;">
  <v-btn large    class=" white--text mr-1 ml-1  mt-2 mb-0  " color="rgba(0, 157, 16, 0.8)" @click="newprog=true" style=" ;border:2px solid rgba(12, 69, 14, 0.32)"><v-icon large color="#ffffff" class="mr-2" >mdi-folder-plus</v-icon>Создать</v-btn>
  <v-btn large   class=" white--text mr-1 ml-1  mt-2 mb-0  " color="rgba(0, 157, 16, 0.8)"  @click="dialogkod=true; kod='';" style=" ;border:2px solid rgba(12, 69, 14, 0.32)"><v-icon   class="mr-2"   large color="#ffffff">mdi-open-in-new</v-icon>Открыть</v-btn>

</div>
</div>

<p v-if="showwidth()<1200" class="body-0 pr-2 pl-2 mb-4 text-center" style="border: 0px solid rgb(175, 175, 175);line-height:1.2rem; color:#333;  width:95%; margin:0px auto; padding:15px; border-radius:15px;">
  <v-icon x-large  class="mr-2" >mdi-monitor-screenshot</v-icon>
  <v-icon x-large  class="mr-2" >mdi-cellphone-off</v-icon><br/><br/> Для комфортной работы <br/>Вам потребуется монитор</p>


<v-overlay :value="overlayer" style="z-index:500;">

<v-progress-circular
indeterminate
size="64"
></v-progress-circular><br/>
<span class="display-1"> Пожалуйста, подождите</span>
</v-overlay>

<v-dialog
      v-model="newprog"
      transition="dialog-bottom-transition"
      max-width="800" :fullscreen="showwidth()<500"
    >


      <v-card>
        <v-card-title class="text-h5 grey lighten-2 " style="word-break: keep-all;">
          Создание программы энергосбережения
        </v-card-title>

        <v-card-text ref="form" class="body-1 black--text">

          <br/>
          Введите любое рабочее название

          <br/>
          <br/>
         <form @submit.prevent="submitnewprog">
          <v-text-field outlined
                      ref="titlerab"
                      v-model="titlerab"
                      :rules="[rules.required]"
                      label="Рабочее наименование"

                    ></v-text-field>


           Заполните поля e-mail. Вводите реальный адрес. Дальнейший доступ к программе производится по коду или по ссылке из письма, направляемого по электронной почте.

          <br/>
          <br/>


          <v-text-field outlined
            v-model="emailrab"
            ref="emailrab"
            :rules="[rules.required, rules.email]"
            label="Введите email"
          ></v-text-field>



          <v-text-field outlined
            v-model="emailrab2"
            ref="emailrab2"
            :rules="[() => emailrab2==emailrab || 'Не совпадает']"
            label="Введите email повторно"
          ></v-text-field>
          </form>
        </v-card-text>



        <v-divider></v-divider>

        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn large   class=" white--text mr-2 ml-2  mt-2 mb-2  " color="rgba(203, 65, 6, 0.8)"  @click="newprog = false" style=" ;border:2px solid rgba(198, 0, 0, 0.32)"><span class="pl-2 pr-2 ">Закрыть</span></v-btn>

          <v-btn large    class=" white--text mr-2 ml-2  mt-2 mb-2 " color="rgba(0, 157, 16, 0.8)"   @click="submitnewprog" style=" ;border:2px solid rgba(12, 69, 14, 0.32)"><span class="pl-2 pr-2 ">Создать</span></v-btn>


        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
          v-model="dialogvosst"
          transition="dialog-bottom-transition"
          max-width="800" :fullscreen="showwidth()<500"
        >


          <v-card>
            <v-card-title class="text-h5 grey lighten-2 " style="word-break: keep-all;">
              Восстановление доступа
            </v-card-title>

            <v-card-text ref="form3" class="body-1 black--text">
              <br/>
              Введите e-mail, по которому ранее направлялись реквизиты доступа.

              <br/>
              <br/>
              <form @submit.prevent="submitvosst">
              <v-text-field outlined
                          ref="emailrab"
                          v-model="emailrab"
                          :rules="[rules.required, rules.email]"
                          label="E-mail для восстановления доступа"

                        ></v-text-field>

                      </form>


            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>

              <v-spacer></v-spacer>


              <v-btn large   class=" white--text mr-2 ml-2  mt-2 mb-2  " color="rgba(203, 65, 6, 0.8)"  @click="dialogvosst = false" style=" ;border:2px solid rgba(198, 0, 0, 0.32)"><span class="pl-2 pr-2 ">Закрыть</span></v-btn>

              <v-btn large    class=" white--text mr-2 ml-2  mt-2 mb-2 " color="rgba(0, 157, 16, 0.8)"   @click="submitvosst" style=" ;border:2px solid rgba(12, 69, 14, 0.32)"><span class="pl-2 pr-2 ">Восстановить доступ</span></v-btn>


            </v-card-actions>
          </v-card>
        </v-dialog>

    <v-dialog
          v-model="dialogkod"
          transition="dialog-bottom-transition"
          max-width="800" :fullscreen="showwidth()<500"
        >


          <v-card>
            <v-card-title class="text-h5 grey lighten-2 " style="word-break: keep-all;">
              Открытие программы энергосбережения
            </v-card-title>

            <v-card-text ref="form2" class="body-1 black--text">
              <br/>
              Введите код программы из ранее полученного письма. <v-btn x-small text dense    class="  mr-2 ml-2  mt-2 mb-2 info "    @click="dialogkod=false;dialogvosst=true;" ><span class="pl-2 pr-2 ">Восстановить доступ по e-mail</span></v-btn>

              <br/>
              <br/>
              <form @submit.prevent="submitkod">
              <v-text-field outlined
                          ref="kod"
                          v-model="kod"
                          :rules="[rules.required]"
                          label="Код программы"

                        ></v-text-field>

                      </form>


            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>

              <v-spacer></v-spacer>


              <v-btn large   class=" white--text mr-2 ml-2  mt-2 mb-2  " color="rgba(203, 65, 6, 0.8)"  @click="dialogkod = false" style=" ;border:2px solid rgba(198, 0, 0, 0.32)"><span class="pl-2 pr-2 ">Закрыть</span></v-btn>

              <v-btn large    class=" white--text mr-2 ml-2  mt-2 mb-2 " color="rgba(0, 157, 16, 0.8)"   @click="submitkod" style=" ;border:2px solid rgba(12, 69, 14, 0.32)"><span class="pl-2 pr-2 ">Перейти к программе</span></v-btn>


            </v-card-actions>
          </v-card>
        </v-dialog>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="dialogok"

      >

          <v-card>
            <v-toolbar
              color="success"
              dark
            >Успешно</v-toolbar>
            <v-card-text>
              <div class="body-1 pa-2 black--text">{{oktext}}</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn large   class=" white--text mr-2 ml-2  mt-0 mb-2  " color="rgba(0, 157, 16, 0.8)"   @click="dialogok = false" style=" ;border:2px solid rgba(12, 69, 14, 0.32)"><span class="pl-2 pr-2 ">Закрыть</span></v-btn>



            </v-card-actions>
          </v-card>

      </v-dialog>




    <v-snackbar v-model="snackbarOK" :timeout="10000">
        {{oktext}}
        <v-btn color="success" @click="snackbarOK = false">
            закрыть
        </v-btn>
    </v-snackbar>

    <v-snackbar v-model="snackbarErr" :timeout="10000">
      {{errtext}}
        <v-btn color="error" @click="snackbarErr = false">
            закрыть
        </v-btn>
    </v-snackbar>



</div>



</template>



<script>
  // Enable pusher logging - don't include this in production
  //Pusher.logToConsole = true;



  const axios = require('axios').default


  //import draggable from "vuedraggable"
  import Vue from 'vue';
//  import { createApp } from "vue";


  import VueCookies from 'vue-cookies';




  Vue.use(VueCookies);

















  //import html2canvas from 'html2canvas';



  export default {

    data: () => ({

      snackbarOK: false,
      dialogvosst:false,
      dialogok:false,
      oktext:'',
      errtext:'',
      snackbarErr: false,





      formnewrogHasErrors: false,


      titlerab:'Программа на ... годы по участку ... теплоснабжения',
      emailrab:'',
      emailrab2:'',

      rules: {
         required: value => !!value || 'Не заполнено',
         counter: value => value.length <= 20 || 'Max 20 characters',
         email: value => {
           const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
           return pattern.test(value) || 'Проверьте e-mail.'
         },


          },


      RI:[],
      rizero:false,
      ri_caption:{'TS': 'Теплоснабжение', 'HVS':'Водоснабжение','VO':'Водоотведение','GVS':'ГВС'},
      RIprogress:false,
      firms:[],
      prikazieedata:[],
      full:'',
      short:'',
      mo:'',
      prikaziprogress:false,
      printvisible:false,
      girbozero:false,
      ermspprogress:false,
      egrulprogress:false,
      rosstatprogress:false,
      fnsprogress:false,
      err:{message:''},
      egruldata:[],
      mspdata:[],
      fnsdata:[],
      rosstatdata:[],
      prikazidata:[],
      inn:"",
      tab1:null,
      te:true,
      trte:false,
      tn:false,
      gvs:false,
      vs:true,
      vo:true,
      trvs:false,
      trvo:false,
      ee:true,
      osno:true,
      usno:false,
      nebudg:true,
      budg:false,
      nemp:true,
      mp:false,
      nep4:false,
      p4:true,
      do15:false,
      ot15:true,
      tereg:true,
      tenereg:false,
      helpdialog:false,
      vd:['te',	'tn',	'gvs',	'vs',	'vo',	'trte',	'trvs',	'trvo',	'ee',	'osno',	'usno',	'nebudg',	'budg',	'nemp',	'mp',	'nep4',	'p4',	'do15',	'ot15',	'tereg',	'tenereg'],
      vdtext:['Теплоснабжение',	'Теплоноситель',	'ГВС',	'Водоснабжение',	'Водоотведение',	'Транспортировка тепловой энергии',	'Транспортировка воды',	'Транспортировка стоков',	'Передача электроэнергии',	'osno',	'usno',	'nebudg',	'budg',	'nemp',	'mp',	'nep4',	'p4',	'do15',	'ot15',	'tereg',	'tenereg'],
      nalog:[],

      months:['Отчёты и документы с изменяющимися датами сдачи','Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      monthshort:['','янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
      calendar:[],
      sendingprocessdown:false,
      download:false,
      flagnow:false,
      recordobj:'',
      overlayer:false,

      flag_stop_redact:0,

                 ch1:[{message:''}],



      emailadd: false,
      sending: false,
      word: '',
      about: false,
      balance_upload: 0,
      commentaddindex: '',
      commentaddmsg: '',
      commentadd: false,

      showallperechen: true,
      tomsize_font: '16',
      expanded: [],

      tab: null,
      sel_reestr: "",
      per_direction: 2,
      centerlabel: true,
      sel_delo: {},
      sel_podacha: {},
      kind_titul: 'T',
      scale: 5,
      search: "",
      search1: "",
      move_doc: false,
      move_flag: false,
      dialog_edit_text: "",
      doc: [],
      list_dela: [],
      podacha1: {},
      files: [],
      output: null,
      org_height: 120,
      newprog: false,
      dialogkod:false,
      kod:"",
      dialog_titul: false,

      dialog_perechen: false,
      notice_print: false,
      label_print: false,
      label_count: 2,
      label_fontsize: 16,
      label_width: 200,
      label_height: 55,
      label_tom: " ",
      settings_pod: false,

      titul_text: "",
      pd: {},

      id: '0',
      show_ov_1: false,
      docs_over: [],
      show_ov_2: false,
      show_ov_3: false,
      eias_over: [],
      files_over: [],
      reestr_over: [],
      kods: [],
      file_links: [],
      index: null,
      drawer: null,
      dis: false,
      show: false,
      stroka_vivod: "",
      orgs: false,
      notes: false,
      jobs: false,
      navigation: {
        shown: true,
        width: 500,
        borderSize: 3
      },




    }),
    watch: {


    },


    computed: {



      formnewprog () {
        return {
          titlerab: this.titlerab,
          emailrab: this.emailrab,
          emailrab2: this.emailrab2,

        }
     },
     formkod () {
       return {
         kod: this.kod,


       }},
     formvosst () {
         return {
           emailrab: this.emailrab,


         }
    },



      direction() {
        return this.navigation.shown === false ? "Open" : "Closed";
      },







    },

    methods: {

      getData() {
          window.console.log(this.$refs.spreadsheet.current[0].getData());
       },



      submitnewprog () {
        this.formnewrogHasErrors = false
        let self = this;

        Object.keys(this.formnewprog).forEach(f => {
          if (!this.formnewprog[f]) this.formnewrogHasErrors = true

          this.$refs[f].validate(true);


        })

        if (!this.formnewrogHasErrors)
        {
          self.overlayer=true;

          axios.post(
              'https://261.рф/NewPE',
              JSON.stringify({
                  enter: "123",
                  titlerab:self.titlerab,
                  emailrab:self.emailrab
              })
            )
            .then(function(response) {

              if (
                self.Isnoproblem(response.data))
              {
                self.overlayer=false;

                window.console.log(response.data[0]);
                self.oktext="Программа создана! Ссылка на Вашу программу находится в письме, направленном Вам по электронной почте - " + response.data[0]['email_rab'] + "." ;
                self.emailrab="";
                self.titlerab='Программа на ... годы по участку ... теплоснабжения';
                self.emailrab2="";
                self.newprog=false;
                self.dialogok=true;
              }
              else {
                self.overlayer=false;
                self.errtext="Ошибка! Попробуйте повторить позднее.";
                self.snackbarErr=true;
              }

            })

            .catch(function(error) {
              self.overlayer=false;
              window.console.log(error);
            })

        }
      },


      submitkod () {
        this.formnewrogHasErrors = false
        let self = this;

        Object.keys(this.formkod).forEach(f => {
          if (!this.formkod[f]) this.formnewrogHasErrors = true

          this.$refs[f].validate(true);


        })

        if (!this.formnewrogHasErrors)
        {
          self.overlayer=true;

          axios.post(
              'https://261.рф/checkkod',
              JSON.stringify({
                  enter: "123",
                  kod:self.kod

              })
            )
            .then(function(response) {

              if (
                self.Isnoproblem(response.data))
              {
                self.overlayer=false;



                window.console.log(response.data[0]);
                if (response.data[0]['CHECK']=="1")
                {
                self.$router.push('/getprogram/'+self.kod);

                }
                else {

                  self.overlayer=false;
                  self.errtext="Код не найден! Проверьте правильность ввода, воспользуйтесь ссылкой в письме или попробуйте повторить позднее.";
                  self.snackbarErr=true;

                }

              }
              else {
                self.overlayer=false;
                self.errtext="Ошибка! Попробуйте повторить позднее.";
                self.snackbarErr=true;
              }

            })

            .catch(function(error) {
              self.overlayer=false;
              window.console.log(error);
            })

        }
      },

      submitvosst () {
        this.formnewrogHasErrors = false
        let self = this;

        Object.keys(this.formvosst).forEach(f => {
          if (!this.formvosst[f]) this.formnewrogHasErrors = true

          this.$refs[f].validate(true);


        })

        if (!this.formnewrogHasErrors)
        {
          self.overlayer=true;

          axios.post(
              'https://261.рф/vosstkod',
              JSON.stringify({
                  enter: "123",
                  email:self.emailrab

              })
            )
            .then(function(response) {

              if (
                self.Isnoproblem(response.data))
                {
                  self.overlayer=false;
                  window.console.log(response.data[0]);
                  self.oktext="Ссылки на Ваши программы ("+response.data.length +" шт.) находится в письме, направленном Вам по электронной почте - " + self.emailrab + "." ;
                  self.emailrab="";
                  self.dialogvosst=false;
                  self.dialogok=true;
                }
                else {
                  self.overlayer=false;
                  self.errtext="Ошибка! Попробуйте повторить позднее.";
                  self.snackbarErr=true;
                }
            })

            .catch(function(error) {
              self.overlayer=false;
              window.console.log(error);
            })

        }
      },





      printoutcalendar()
      {
                //
                // let divToPrint = window.document.getElementById("calendarprint");
                // let newWin = window.open('');
                // newWin.document.write(divToPrint.outerHTML);
                // newWin.print();



                window.setTimeout(() => {
                const prtHtml = document.getElementById('calendarprint').innerHTML;

                // Get all stylesheets HTML
                let stylesHtml = '';

                for (const node of [...document.querySelectorAll('link[rel="stylesheet"]')]) {
                  stylesHtml += node.outerHTML;
                }


                // Open the print window

                const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                    ${stylesHtml}
                  </head>
                  <body style="background: rgb(255, 255, 255);">
                    ${prtHtml}
                  </body>
                </html>`);



                  WinPrint.document.close();
                  WinPrint.focus();

                  window.setTimeout(() => {WinPrint.focus(); WinPrint.print()}, 2000)
                  this.printvisible=false;
                }, 1000)
                  //WinPrint.print();
                  //WinPrint.close();



            },

      validateInn(inn, error) {
 var result = false;
 if (typeof inn === 'number') {
   inn = inn.toString();
 } else if (typeof inn !== 'string') {
   inn = '';
 }
 if (!inn.length) {

   error.message = 'ИНН пуст';
 } else if (/[^0-9]/.test(inn)) {

   error.message = 'ИНН может состоять только из цифр';
 } else if ([10, 12].indexOf(inn.length) === -1) {

   error.message = 'ИНН может состоять только из 10 или 12 цифр';
 } else {
   var checkDigit = function (inn, coefficients) {
     var n = 0;
     for (var i in coefficients) {
       n += coefficients[i] * inn[i];
     }
     return parseInt(n % 11 % 10);
   };
   switch (inn.length) {
     case 10:
       var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
       if (n10 === parseInt(inn[9])) {
         result = true;
       }
       break;
     case 12:
       var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
       var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
       if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
         result = true;
       }
       break;
   }
   if (!result) {

     error.message = 'Неправильное контрольное число';
   }
 }
 if (result)
 {
   this.girbozero=false;this.rizero=false;this.egruldata=[];this.mspdata={'data':[]};this.fnsdata=[];this.rosstatdata=[]; this.prikazieedata=[]; this.full=''; this.short='';this.mo=''
   this.prikazidata=[]; this.RI=[]; this.getRI();this.rosstat();this.girbo();this.egrul();this.msp(); this.prikaziee();
   this.err.message=""
 }
 else {
   this.err.message=error.message
 }

 return result;
},

      rosstat()
      {
        var self = this;
        self.rosstatprogress=true;
        axios.post(
            'http://calendar.btr-online.ru/Getrosstat',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {
            if (
              response.data != "ERROR") {
                self.rosstatdata=response.data;
                self.rosstatprogress=false;

            } else {
              self.rosstatprogress=false;
            }
          })
          .catch(function(error) {
            window.console.log(error);
            self.rosstatprogress=false;
          });
      },


            prikazi()
            {



              var self = this;
              var short='--';
              var full='--';
              var mo="--";
              self.prikaziprogress=true;

              if (self.short!='')
              {
                if (self.short.indexOf('"')>-1)
                {
                  short='«'+self.short.match(/"(.+)"/)[1]+'»'
                }
                else {
                  short='«'+self.short+'»'
                }

              }

              if (self.full!='')
              {
                if (self.full.indexOf('"')>-1)
                {
                  full='«'+self.full.match(/"(.+)"/)[1]+'»'
                }
                else {
                  full='«'+self.full+'»'
                }

              }

              if(self.fnsdata.okopf.name.indexOf('Муниц')>-1)
              {

                if (self.fnsdata.city)
                {
                  mo=self.fnsdata.city.slice(0,-3)
                }
                else {
                  mo=self.fnsdata.district.slice(0,-3)
                }



              }

              axios.post(
                  'http://calendar.btr-online.ru/prikazi',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,
                      short: short,
                      mo:mo,
                      full:  full,
                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.prikazidata=response.data;
                      self.prikaziprogress=false;

                  } else {
                    self.prikaziprogress=false;
                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.prikaziprogress=false;
                });
            },
            prikazi2()
            {



              window.setTimeout(() => {



                var self = this;
                var short='--';
                var full='--';
                var mo='--';

                this.log('prikazi2');
                self.prikaziprogress=true;
                if (self.egruldata)
                {

                  if ((self.egruldata['rows'][0].k=='fl'))

                  {


                    self.short=self.egruldata['rows'][0].n.split(" ")[0].slice(0,-2);
                    self.full=self.egruldata['rows'][0].n.split(" ")[1].slice(0,1)+'.'+self.egruldata['rows'][0].n.split(" ")[2].slice(0,1)+'.';
                    self.prikazi3();
                    return;


                  }

                 else {



                   if (self.egruldata['rows'][0].c!='')
                   {
                     if (self.egruldata['rows'][0].c.indexOf('"')>-1)
                     {
                       short='«'+self.egruldata['rows'][0].c.match(/"(.+)"/)[1]+'»'
                     }
                     else {
                       short='«'+self.egruldata['rows'][0].c+'»'
                     }

                   }

                   if (self.egruldata['rows'][0].n!='')
                   {
                     if (self.egruldata['rows'][0].n.indexOf('"')>-1)
                     {
                       full='«'+self.egruldata['rows'][0].n.match(/"(.+)"/)[1]+'»'
                     }
                     else {
                       full='«'+self.egruldata['rows'][0].n+'»'
                     }

                   }

                 }
               }






                axios.post(
                    'http://calendar.btr-online.ru/prikazi',
                    JSON.stringify({
                        enter: "123",
                        inn:self.inn,
                        short:short,
                        mo:mo,
                        full: full,

                    })
                  )
                  .then(function(response) {
                    if (
                      response.data != "ERROR") {
                        self.prikazidata=response.data;
                        self.prikaziprogress=false;

                    } else {
                      self.prikaziprogress=false;
                    }
                  })
                  .catch(function(error) {
                    window.console.log(error);
                    self.prikaziprogress=false;
                  });


              }, 3000)


            },

            prikazi3()
            {
              var self = this;
              self.prikaziprogress=true;

              axios.post(
                  'http://calendar.btr-online.ru/prikaziip',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,
                      short: self.short,
                      full:  self.full
                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.prikazidata=response.data;
                      self.prikaziprogress=false;

                  } else {
                    self.prikaziprogress=false;
                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.prikaziprogress=false;
                });
            },
            prikaziee()
            {
              var self = this;
              self.prikaziprogress=true;

              axios.post(
                  'http://calendar.btr-online.ru/prikaziee',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,

                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.prikazieedata=response.data;
                      self.prikaziprogress=false;

                  } else {
                    self.prikaziprogress=false;
                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.prikaziprogress=false;
                });
            },
            getRI()
            {
              var self = this;
              self.prikaziprogress=true;

              axios.post(
                  'http://calendar.btr-online.ru/GetRINFO',
                  JSON.stringify({
                      enter: "123",
                      inn:self.inn,

                  })
                )
                .then(function(response) {
                  if (
                    response.data != "ERROR") {
                      self.RI=response.data;
                      self.RIprogress=false;
                      if (self.RI.length===0)
                      {
                          self.rizero=true;

                      }

                  } else {
                    self.RIprogress=false;

                  }
                })
                .catch(function(error) {
                  window.console.log(error);
                  self.RIprogress=false;
                });
            },

      girbo()
      {
        var self = this;
        self.fnsprogress=true;
        axios.post(
            'http://calendar.btr-online.ru/girbo',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {
            if (response.data != "ERROR") {
                self.fnsdata=response.data;
                self.fnsprogress=false;
                self.full=self.fnsdata['fullName'];
                self.short=self.fnsdata['shortName'];
                self.prikazi()

            } else {
            self.fnsprogress=false;
            self.girbozero=true;
            self.prikazi2();



            }
          })
          .catch(function(error) {
            window.console.log(error);
            self.fnsprogress=false;
            self.prikazi2();

          });
      },

      egrul()
      {
        var self = this;
        self.egrulprogress=true;
        axios.post(
            'http://calendar.btr-online.ru/egrul',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {
            if (
              response.data != "ERROR") {
                self.egruldata=response.data;
                self.egrulprogress=false;

            } else {

              self.egrulprogress=false;
            }
          })
          .catch(function(error) {
            window.console.log(error);
            self.egrulprogress=false;
          });
      },
      msp()
      {
        var self = this;
        self.ermspprogress=true;
        axios.post(
            'http://calendar.btr-online.ru/Getmsp',
            JSON.stringify({
                enter: "123",
                inn:self.inn,
            })
          )
          .then(function(response) {


            try {


            if ( (  response.data !== "ERROR") && (('ERROR' in response.data)==false)  ) {
                self.mspdata=response.data;
                self.ermspprogress=false;

            } else
            {
              self.ermspprogress=false;
            }
          }
            catch(error) {
              window.console.log(error);
              self.ermspprogress=false;
            }
          })

      },

      refresh() {
    window.location.reload();
},
       convertToBoolean(sss) {
          try {
              return JSON.parse(sss.toLowerCase());
          }
          catch (e) {
              return undefined;
          }
      },

      savecookie()
      {

          this.vd.forEach((item) => {
              this.$cookies.set(item, this[item],31556926);
          });

      },


showwidth()
{
  return window.document.documentElement.clientWidth
},


      reportfilter(elem)
      {
        var  result=false;



     for (var i = 2; i < 9; i++) {
        result=result || (((this[this.vd[i]]+0)+(elem[this.vd[i]]+0))===2)

     }


     if ((this['te']+0)===1)
     {
         if ((this['tereg']+0)===1)  {
            result=result || ((elem['tereg']+0)===1)
         }
         else {
           result=result || ((elem['tenereg']+0)===1)
         }
      }

      if ((this['tn']+0)===1)
      {
          if ((this['tereg']+0)===1)  {
             result=result || (((elem['tereg']+0)===1) && ((elem['tn']+0)===1))
          }
          else {
            result=result || (((elem['tenereg']+0)===1) && ((elem['tn']+0)===1))
          }
       }


     for (var j = 9; j < 19; j++) {

       if ((this[this.vd[j]]+0)===1) {
          if (((elem[this.vd[j]]+0)===1))
          {
             result=result && ((elem[this.vd[j]]+0)===1)
          }
          else {
            result=false;
            break;
          }
       }



     }



        return (result )
      },

      calcreports(massiv)
      {
        let sum=0

        massiv.forEach((item) => {

          for (var i = 1; i < 13; i++) {
            sum=sum+((item['MON'+i]!==null)+0)
          }
          sum=sum+((item['specdata']!==null)+0)

        });

        return sum
      },













      Isnoproblem(str) {
        try {
          str.trim();
        } catch (e) {
          return true;
        }
        return false;
      },

      log(dd) {
        window.console.log(dd)
      },








      styleDoc(i, img) {

        return "backgroundImage: url(" + img + "); width: " + 680 * this.zoom[i] / 100 + "px; height: " + 963 * this.zoom[i] / 100 + "px; transform: rotate(" + this.rot[i] + "deg);"
      },
      setBorderWidth() {
        let i = this.$refs.drawer.$el.querySelector(
          ".v-navigation-drawer__border"
        );
        i.style.width = this.navigation.borderSize + "px";
        i.style.cursor = "ew-resize";
      },
      setEvents() {
        const minSize = this.navigation.borderSize;
        const el = this.$refs.drawer.$el;
        const drawerBorder = el.querySelector(".v-navigation-drawer__border");
        const vm = this;
        const direction = el.classList.contains("v-navigation-drawer--right") ?
          "right" :
          "left";

        function resize(e) {
          document.body.style.cursor = "ew-resize";
          let f = direction === "right" ?
            document.body.scrollWidth - e.clientX :
            e.clientX;
          el.style.width = f + "px";
        }

        drawerBorder.addEventListener(
          "mousedown",
          function(e) {
            if (e.offsetX < minSize) {
              ///m_pos = e.x;
              el.style.transition = 'initial';
              document.addEventListener("mousemove", resize, false);
            }
          },
          false
        );

        document.addEventListener(
          "mouseup",
          function() {
            el.style.transition = '';
            vm.navigation.width = el.style.width;
            document.body.style.cursor = "";
            document.removeEventListener("mousemove", resize, false);
          },
          false
        );
      },



    },

    mounted() {





      if (!this.$cookies.isKey('te'))
      {



                    this.vd.forEach((item) => {
                        this.$cookies.set(item, this[item],31556926);
                    });

      }
      else {

        this.vd.forEach((item) => {

            this[item]=this.convertToBoolean(this.$cookies.get(item));
        });

      }










    },

  };
</script>
